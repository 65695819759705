<template>
  <div class="container">
    TeacherList
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'

export default {
  name: 'TeacherList',
  mixins: [PageMixin],
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
<style lang="less" scoped>

</style>

